@import "../lib/bootstrap/dist/css/bootstrap.min.css";

.popover {
  background: red;
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: red;
}

#invitation_response_options {

  h5 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 1.5rem;
  }

  label {
    display: block;
  }

  .custom-control-label::before {
    top: 0.01em;
  }
  .custom-control-label::after {
    top: 0.01em;
  }
}

